import { useConfigStore } from '../stores/config';

export const SettingsPage = () => {
    const [githubToken, diffView] = useConfigStore((s) => [s.githubToken, s.diffView]);
    const [setGithubToken, setDiffView] = useConfigStore((s) => [s.setGithubToken, s.setDiffView]);

    return (
        <>
            <h2>Settings</h2>

            <form>
                <div className="row mb-3">
                    <label htmlFor="input-setting-github-token" className="col-sm-2 col-form-label">
                        GitHub token
                    </label>
                    <div className="col-sm-10">
                        <input
                            type="text"
                            className="form-control"
                            id="input-setting-github-token"
                            value={githubToken}
                            onInput={(e) => setGithubToken(e.currentTarget.value)}
                        />
                        <div className="form-text">
                            You need to specify a{' '}
                            <a href="https://github.com/settings/tokens/new?scopes=repo">personal access token</a> for
                            data editor to be able to act on your behalf on GitHub. The token needs to have the{' '}
                            <code>repo</code> scope.
                            <br />
                            <a href="https://github.com/datenanfragen/data-editor/issues/5">
                                Support for fine-grained access tokens
                            </a>{' '}
                            is planned but doesn’t work yet.
                        </div>
                    </div>
                </div>

                <div className="row mb-3">
                    <label htmlFor="input-setting-diff-view" className="col-sm-2 col-form-label">
                        Diff view
                    </label>
                    <div className="col-sm-10">
                        <select
                            id="input-setting-diff-view"
                            className="form-select"
                            value={diffView}
                            onChange={(e) => setDiffView(e.currentTarget.value as 'split')}>
                            <option value="unified">unified</option>
                            <option value="split">split</option>
                        </select>
                    </div>
                </div>
            </form>
        </>
    );
};
