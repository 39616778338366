// src/Form.tsx
import { AggregateAjvError } from "@segment/ajv-human-errors";
import Ajv from "ajv";
import { nanoid } from "nanoid";
import { useEffect, useMemo, useState } from "preact/hooks";

// src/schema-renderer/index.tsx
import c5 from "classnames";
import { isMatch } from "matcher";

// src/store.ts
import { createStore } from "@udecode/zustood";
import jsonpointer from "jsonpointer";
var objectStore = createStore("object")({
  objects: {}
}).extendSelectors((state) => ({
  getObject: (id) => state.objects[id] || {},
  getForPointer: (id, pointer) => jsonpointer.get(state.objects[id] || {}, pointer)
})).extendActions((set) => ({
  overrideObject: (id, object) => {
    set.state((draft) => {
      draft.objects[id] = object;
    });
  },
  setForPointer: (id, pointer, _value) => {
    const value = _value === "" || Array.isArray(_value) && _value.length === 0 ? void 0 : _value;
    set.state((draft) => {
      if (!draft.objects[id])
        draft.objects[id] = {};
      jsonpointer.set(draft.objects[id], pointer, value);
    });
  }
}));

// src/schema-renderer/array.tsx
import c from "classnames";

// src/util.ts
var swapElements = (array, i, j) => {
  if (i < 0 || i >= array.length || j < 0 || j >= array.length)
    throw new Error("Invalid index.");
  const arrayCopy = [...array];
  [arrayCopy[i], arrayCopy[j]] = [arrayCopy[j], arrayCopy[i]];
  return arrayCopy;
};
var emptyDefaultForJsonSchema = (schema, options) => {
  if (typeof schema === "boolean")
    return schema;
  if (schema.default !== void 0)
    return schema.default;
  if (schema.type === "object") {
    const object = {};
    for (const [key, value] of Object.entries(schema.properties ?? {}))
      object[key] = emptyDefaultForJsonSchema(value);
    return object;
  }
  if (schema.type === "null")
    return null;
  if (options?.isNewArrayElement === true)
    return null;
  return void 0;
};

// src/schema-renderer/array.tsx
import { Fragment, jsx, jsxs } from "preact/jsx-runtime";
var ArrayRenderer = ({ schema, elementIds, ...props }) => {
  const items = schema.items;
  if (!items)
    throw new Error("Array schema must have items.");
  if (Array.isArray(items))
    throw new Error("Currently, only array with object items are supported.");
  if (typeof items === "boolean")
    return /* @__PURE__ */ jsx(Fragment, {});
  const value = objectStore.useTracked.getForPointer(props.storeId, props.pointer) || [];
  const buttons = ({ index, clazz }) => /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      "button",
      {
        className: clazz,
        type: "button",
        title: "Delete this item",
        onClick: () => objectStore.set.setForPointer(
          props.storeId,
          props.pointer,
          value.filter((_, i) => i !== index)
        ),
        children: /* @__PURE__ */ jsx("i", { className: "bi-trash" })
      }
    ),
    /* @__PURE__ */ jsx(
      "button",
      {
        className: clazz,
        type: "button",
        title: "Move this item up",
        disabled: index === 0,
        onClick: () => objectStore.set.setForPointer(props.storeId, props.pointer, swapElements(value, index, index - 1)),
        children: /* @__PURE__ */ jsx("i", { className: "bi-arrow-up" })
      }
    ),
    /* @__PURE__ */ jsx(
      "button",
      {
        className: clazz,
        type: "button",
        title: "Move this item down",
        disabled: index === value.length - 1,
        onClick: () => objectStore.set.setForPointer(props.storeId, props.pointer, swapElements(value, index, index + 1)),
        children: /* @__PURE__ */ jsx("i", { className: "bi-arrow-down" })
      }
    )
  ] });
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    value.map(
      (_, index) => ["object", "array"].includes(items.type) || items.format === "text" ? /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsxs("div", { className: "row mb-3", children: [
        /* @__PURE__ */ jsx("div", { className: "col-sm-1", children: /* @__PURE__ */ jsx("div", { className: "btn-group-vertical", role: "group", "aria-label": "Vertical button group", children: buttons({ index, clazz: "btn btn-sm btn-outline-secondary" }) }) }),
        /* @__PURE__ */ jsx("div", { className: "col-sm", children: /* @__PURE__ */ jsx(
          SchemaRenderer,
          {
            id: `${props.id}-${index}`,
            pointer: `${props.pointer}/${index}`,
            storeId: props.storeId,
            schema: items,
            required: false,
            errors: props.errors,
            helpers: props.helpers
          }
        ) })
      ] }) }) : /* @__PURE__ */ jsxs("div", { className: "input-group input-group-sm mb-3", children: [
        buttons({ index, clazz: "btn btn-outline-secondary" }),
        /* @__PURE__ */ jsx(
          SchemaRenderer,
          {
            id: `${props.id}-${index}`,
            pointer: `${props.pointer}/${index}`,
            storeId: props.storeId,
            schema: items,
            required: false,
            errors: props.errors,
            helpers: props.helpers
          }
        )
      ] })
    ),
    /* @__PURE__ */ jsx(
      "button",
      {
        type: "button",
        className: c("btn", "btn-sm", "me-2", {
          "is-invalid": props.hasError,
          "btn-primary": !props.hasError,
          "btn-danger": props.hasError
        }),
        onClick: () => objectStore.set.setForPointer(
          props.storeId,
          `${props.pointer}/-`,
          emptyDefaultForJsonSchema(items, { isNewArrayElement: true })
        ),
        children: "Add item"
      }
    )
  ] });
};

// src/schema-renderer/boolean.tsx
import c2 from "classnames";
import { jsx as jsx2 } from "preact/jsx-runtime";
var values = [
  { value: void 0, label: "" },
  { value: true, label: "true" },
  { value: false, label: "false" }
];
var BooleanRenderer = ({
  pointer,
  storeId,
  elementIds,
  schema,
  required,
  hasError,
  eventHandlers
}) => {
  const value = objectStore.useTracked.getForPointer(storeId, pointer);
  return /* @__PURE__ */ jsx2(
    "select",
    {
      className: c2("form-select", "form-select-sm", { "is-invalid": hasError }),
      id: elementIds.input,
      required,
      value,
      ...eventHandlers,
      onChange: (e) => {
        objectStore.set.setForPointer(
          storeId,
          pointer,
          { true: true, false: false, undefined: void 0 }[e.currentTarget.value]
        );
        eventHandlers.onChange?.(e);
      },
      children: values.filter(
        (v) => !schema.enum || schema.enum.includes(v.value) || v.value === void 0 && value === void 0 || v.value === void 0 && !required || value === v.value
      ).map(({ value: v, label }) => /* @__PURE__ */ jsx2("option", { value: `${v}`, children: label }))
    }
  );
};

// src/schema-renderer/null.tsx
import { jsx as jsx3 } from "preact/jsx-runtime";
var NullRenderer = () => /* @__PURE__ */ jsx3("div", { className: "form-control form-control-sm", children: "null" });

// src/schema-renderer/numerical.tsx
import c3 from "classnames";
import { jsx as jsx4 } from "preact/jsx-runtime";
var NumericalRenderer = ({
  pointer,
  storeId,
  elementIds,
  schema,
  step,
  required,
  hasError,
  eventHandlers
}) => schema.enum ? /* @__PURE__ */ jsx4(
  "select",
  {
    className: c3("form-select", "form-select-sm", { "is-invalid": hasError }),
    id: elementIds.input,
    required,
    value: objectStore.useTracked.getForPointer(storeId, pointer) ?? "",
    ...eventHandlers,
    onChange: (e) => {
      objectStore.set.setForPointer(
        storeId,
        pointer,
        e.currentTarget.value === "undefined" ? void 0 : +e.currentTarget.value
      );
      eventHandlers.onChange?.(e);
    },
    children: [
      ...!required || objectStore.useTracked.getForPointer(storeId, pointer) === void 0 ? ["undefined"] : [],
      ...schema.enum
    ].map((v) => /* @__PURE__ */ jsx4("option", { value: v, children: v === "undefined" ? "" : v }))
  }
) : /* @__PURE__ */ jsx4(
  "input",
  {
    type: "number",
    step,
    required,
    className: c3("form-control", "form-control-sm", { "is-invalid": hasError }),
    id: elementIds.input,
    value: objectStore.useTracked.getForPointer(storeId, pointer) ?? "",
    ...eventHandlers,
    onBlur: (e) => {
      objectStore.set.setForPointer(storeId, pointer, +e.currentTarget.value);
      eventHandlers.onBlur?.(e);
    }
  }
);
var NumberRenderer = (props) => /* @__PURE__ */ jsx4(NumericalRenderer, { ...props, step: "any" });
var IntegerRenderer = (props) => /* @__PURE__ */ jsx4(NumericalRenderer, { ...props, step: 1 });

// src/schema-renderer/object.tsx
import { Fragment as Fragment2, jsx as jsx5 } from "preact/jsx-runtime";
var ObjectRenderer = ({ schema, ...props }) => {
  if (!schema.properties)
    throw new Error("Object schema must have properties.");
  return /* @__PURE__ */ jsx5(Fragment2, { children: Object.entries(schema.properties).map(([id, subschema]) => /* @__PURE__ */ jsx5(
    SchemaRenderer,
    {
      id: `${props.id}-${id}`,
      pointer: `${props.pointer}/${id}`,
      storeId: props.storeId,
      schema: subschema,
      required: !!schema.required?.includes(id),
      errors: props.errors,
      helpers: props.helpers
    }
  )) });
};

// src/schema-renderer/string.tsx
import c4 from "classnames";
import { jsx as jsx6 } from "preact/jsx-runtime";
var formatToType = {
  email: "email",
  "idn-email": "email",
  uri: "url",
  "date-time": "datetime-local",
  date: "date",
  time: "time"
};
var StringRenderer = ({
  pointer,
  storeId,
  elementIds,
  schema,
  required,
  hasError,
  eventHandlers
}) => {
  const value = objectStore.useTracked.getForPointer(storeId, pointer);
  const commonProps = {
    required,
    className: c4("form-control", "form-control-sm", { "is-invalid": hasError }),
    id: elementIds.input,
    // See: https://github.com/baltpeter/formj/issues/5
    value: value ?? "",
    ...eventHandlers,
    onChange: (e) => {
      objectStore.set.setForPointer(storeId, pointer, e.currentTarget.value);
      eventHandlers.onChange?.(e);
    }
  };
  return schema.enum ? /* @__PURE__ */ jsx6(
    "select",
    {
      ...commonProps,
      className: c4("form-select", "form-select-sm", { "is-invalid": hasError }),
      onChange: (e) => {
        objectStore.set.setForPointer(
          storeId,
          pointer,
          e.currentTarget.value === "undefined" ? void 0 : e.currentTarget.value
        );
        eventHandlers.onChange?.(e);
      },
      children: [...!required || value === void 0 ? ["undefined"] : [], ...schema.enum].map((v) => /* @__PURE__ */ jsx6("option", { value: v, children: v === "undefined" ? "" : v }))
    }
  ) : schema.format === "text" ? /* @__PURE__ */ jsx6("textarea", { ...commonProps, rows: 5 }) : /* @__PURE__ */ jsx6(
    "input",
    {
      ...commonProps,
      type: schema.format && schema.format in formatToType ? formatToType[schema.format] : "text"
    }
  );
};

// src/schema-renderer/index.tsx
import { Fragment as Fragment3, jsx as jsx7, jsxs as jsxs2 } from "preact/jsx-runtime";
var schemaTypeRenderers = {
  object: ObjectRenderer,
  array: ArrayRenderer,
  string: StringRenderer,
  number: NumberRenderer,
  integer: IntegerRenderer,
  boolean: BooleanRenderer,
  null: NullRenderer
};
var SchemaRenderer = ({ schema, ...props }) => {
  if (typeof schema === "boolean")
    return /* @__PURE__ */ jsx7(Fragment3, {});
  const type = schema.type;
  if (typeof type !== "string")
    throw new Error("Currently, only string types are supported.");
  const elementIds = { row: props.id, input: `${props.id}-input` };
  const value = objectStore.useTracked.getForPointer(props.storeId, props.pointer);
  const errors = props.errors.filter((e) => e.pointer === props.pointer);
  const hasError = errors.length > 0;
  const helpers = props.helpers.map(
    (h) => h({
      pointer: props.pointer,
      value,
      setValue: (newValue) => objectStore.set.setForPointer(props.storeId, props.pointer, newValue)
    })
  ).filter((h) => h.enabled !== false).filter((h) => isMatch(props.pointer, h.pointers));
  const helperButtons = helpers.filter((h) => ["button", "custom-addon"].includes(h.type)).map(
    (h) => h.type === "custom-addon" ? /* @__PURE__ */ jsx7("span", { className: "input-group-text", hidden: h.hidden, children: h.element }) : /* @__PURE__ */ jsx7("button", { type: "button", className: "btn btn-outline-secondary", hidden: h.hidden, ...h.attributes, children: h.children })
  );
  const eventHandlers = Object.entries(
    helpers.filter((h) => h.type === "event-handler").reduce((acc, h) => {
      if (acc[h.event] === void 0)
        acc[h.event] = [];
      acc[h.event].push(h.handler);
      return acc;
    }, {})
  ).reduce(
    (acc, [event, handlers]) => ({
      ...acc,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [event]: (e) => {
        for (const h of handlers)
          h(e);
      }
    }),
    {}
  );
  const suggestions = [
    ...new Set(
      helpers.filter((h) => h.type === "suggestions").flatMap((h) => h.suggestions()).filter((s) => s !== void 0 && s !== value)
    )
  ];
  if (type in schemaTypeRenderers) {
    const SchemaTypeRenderer = schemaTypeRenderers[type];
    const schemaTypeRenderer = /* @__PURE__ */ jsx7(
      SchemaTypeRenderer,
      {
        schema,
        id: props.id,
        pointer: props.pointer,
        storeId: props.storeId,
        elementIds,
        required: props.required,
        hasError,
        errors: props.errors,
        helpers: props.helpers,
        eventHandlers
      }
    );
    const input = /* @__PURE__ */ jsxs2(Fragment3, { children: [
      helperButtons.length > 0 ? ["object", "array"].includes(type) ? /* @__PURE__ */ jsxs2(Fragment3, { children: [
        schemaTypeRenderer,
        /* @__PURE__ */ jsx7("div", { className: c5("btn-group", "btn-group-sm", { "mb-3": type === "object" }), role: "group", children: helperButtons })
      ] }) : /* @__PURE__ */ jsxs2("div", { className: c5("input-group", "input-group-sm", { "is-invalid": hasError }), children: [
        schemaTypeRenderer,
        helperButtons
      ] }) : schemaTypeRenderer,
      errors.map((error) => /* @__PURE__ */ jsx7("div", { className: "invalid-feedback", children: error.message })),
      suggestions.length > 0 && /* @__PURE__ */ jsxs2("div", { className: "form-text", children: [
        "Suggestions:",
        " ",
        suggestions.map((s, i) => /* @__PURE__ */ jsxs2(Fragment3, { children: [
          /* @__PURE__ */ jsx7(
            "button",
            {
              type: "button",
              className: "btn btn-sm btn-link",
              style: "--bs-btn-padding-y: 0; --bs-btn-padding-x: 0;",
              title: `Apply suggestion: ${typeof s === "string" ? s : JSON.stringify(s)}`,
              onClick: () => objectStore.set.setForPointer(props.storeId, props.pointer, s),
              children: /* @__PURE__ */ jsx7("span", { className: "formj-suggestion", children: typeof s === "string" ? s : JSON.stringify(s) })
            }
          ),
          i < suggestions.length - 1 && ", "
        ] }))
      ] })
    ] });
    if (schema.title && props.pointer !== "")
      return /* @__PURE__ */ jsxs2("div", { id: elementIds.row, className: "row mb-3", children: [
        /* @__PURE__ */ jsxs2("label", { for: elementIds.input, className: "col-sm-3 col-form-label col-form-label-sm", children: [
          schema.title,
          props.required && /* @__PURE__ */ jsxs2("span", { className: "text-danger", title: "required", children: [
            " ",
            "*"
          ] }),
          schema.description && /* @__PURE__ */ jsx7("i", { className: "bi bi-info-circle", style: "margin-left: 5px;", title: schema.description })
        ] }),
        /* @__PURE__ */ jsx7("div", { className: "col-sm", children: input })
      ] });
    return input;
  }
  throw new Error(`Unsupported schema type: ${schema.type}`);
};

// src/Form.tsx
import { Fragment as Fragment4, jsx as jsx8, jsxs as jsxs3 } from "preact/jsx-runtime";
var Form = ({ schema, ...props }) => {
  const [rootId] = useState(props.id || `formj-${nanoid()}`);
  useEffect(() => {
    const emptyObject = emptyDefaultForJsonSchema(schema);
    objectStore.set.overrideObject(
      rootId,
      props.initialData !== void 0 ? { ...emptyObject, ...props.initialData } : emptyObject
    );
  }, [schema]);
  const ajv = useMemo(() => props.customAjv || new Ajv(), [props.customAjv]);
  const ajvSchema = useMemo(() => ajv.compile(schema), [ajv, schema]);
  const [validationErrors, setValidationErrors] = useState([]);
  const formApi = {
    overrideObject: (newObj) => objectStore.set.overrideObject(rootId, newObj),
    get: (pointer) => objectStore.get.getForPointer(rootId, pointer),
    set: (pointer, value) => objectStore.set.setForPointer(rootId, pointer, value),
    validate: () => {
      const obj = objectStore.get.getObject(rootId);
      const ajvPassed = ajvSchema(obj);
      const customErrors = props.customValidators?.map((v) => v(obj)).filter((r) => r !== true).flat() || [];
      if (ajvPassed && customErrors.length === 0) {
        setValidationErrors([]);
        return true;
      }
      const ajvErrors = [
        ...new AggregateAjvError(ajvSchema.errors || [], {
          fieldLabels: "js",
          includeData: true,
          includeOriginalError: true
        })
      ].map((e) => {
        if (e.original?.keyword === "required" && e.original?.params?.["missingProperty"])
          e.pointer += "/" + e.original.params["missingProperty"];
        return e;
      });
      const errors = [...ajvErrors, ...customErrors];
      setValidationErrors(errors);
      return errors;
    },
    submit: () => {
      const validationResult = formApi.validate();
      props.onSubmit?.({
        event: "submitted",
        object: objectStore.get.getObject(rootId),
        validationResult
      });
    }
  };
  if (props.onChange)
    objectStore.useStore.subscribe(
      (state, prevState) => props.onChange?.({
        event: "changed",
        object: state.objects[rootId] || {},
        oldObject: prevState.objects[rootId] || {}
      })
    );
  if (props.formApiRef)
    props.formApiRef.current = formApi;
  return /* @__PURE__ */ jsxs3(Fragment4, { children: [
    /* @__PURE__ */ jsx8("style", {
      // This is an ugly workaround. If an array element has helper buttons, we are wrapping it in two
      // `input-group`s (and I can't think of a clean way not to). This causes them to still render
      // correctly.
      children: `#${rootId} .input-group .input-group {
                        flex: auto;
                        width: 1%;
                    }`
    }),
    /* @__PURE__ */ jsx8("form", { id: rootId, noValidate: true, autoComplete: props.autoComplete, onSubmit: (e) => e.preventDefault(), children: /* @__PURE__ */ jsx8(
      SchemaRenderer,
      {
        schema,
        id: rootId,
        pointer: "",
        storeId: rootId,
        required: false,
        errors: props.showValidationErrors === false ? [] : validationErrors,
        helpers: props.helpers || []
      }
    ) })
  ] });
};
export {
  Form
};
