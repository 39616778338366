import { serializeError } from 'serialize-error';
import { flash, FlashMessage } from './components/flash-message';

const messageFromEvent = (event: PromiseRejectionEvent | ErrorEvent) =>
    'error' in event ? event.error?.message : event.reason?.message;

const eventProperties = [
    // Event
    'arguments',
    'bubbles',
    'cancelable',
    'cancelBubble',
    'code',
    'colno',
    'composed',
    'defaultPrevented',
    'description',
    'error',
    'eventPhase',
    'filename',
    'isTrusted',
    'lineno',
    'message',
    'name',
    'returnValue',
    'stack',
    'timeStamp',
    'type',

    // Error
    'columnNumber',
    'fileName',
    'lineNumber',
];

const logError = (event: PromiseRejectionEvent | ErrorEvent | undefined) => {
    if (!event) return;
    /* eslint-disable no-console */
    console.group(
        '%cAn unexpected error occurred:%c ' + messageFromEvent(event),
        'color: red; font-weight: bold; font-size: 1.5em;',
        'font-size: 1.5em;'
    );
    console.log(event);
    console.groupCollapsed(
        'Please submit reports via GitHub (https://github.com/datenanfragen/data-editor/issues) or email (dev@datenanfragen.de) and copy the debug details below.'
    );
    console.log(JSON.stringify(event, eventProperties, 4));
    console.groupEnd();
    console.groupEnd();
    /* eslint-enable no-console */
};

try {
    const handler = (event: ErrorEvent) => {
        try {
            if (
                [
                    'moz-extension://',
                    // `chrome-extension://` also applies to Chromium Edge, Opera, Vivaldi, Yandex.Browser, and Brave.
                    'chrome-extension://',
                    'safari-extension://',
                    '@safari-web-extension://',
                    'ms-browser-extension://',
                    '@webkit-masked-url://hidden/',
                ].some((s) => event.error?.stack?.includes(s))
            )
                return;

            logError(event);

            flash(
                <FlashMessage type="error" duration={null}>
                    <p>
                        An unexpected error occurred:
                        <br />
                        <strong>{messageFromEvent(event)}</strong>
                    </p>
                    <p>Expect undefined behaviour if you continue using data editor without reloading.</p>
                </FlashMessage>
            );
        } catch (handlerError) {
            logError(event);
            // eslint-disable-next-line no-console
            console.error(
                'While handling the error, another one occurred:',
                handlerError,
                '\nas JSON:',
                JSON.stringify(serializeError(handlerError))
            );
        }
    };

    window.addEventListener('error', handler);
    window.addEventListener('unhandledrejection', (event) => {
        const errorEvent = event as unknown as ErrorEvent;
        // @ts-expect-error `ErrorEvent.error` is readonly.
        errorEvent.error = event.reason;
        handler(errorEvent);
    });
} catch (installError) {
    window.addEventListener('unhandledrejection', logError);
    window.addEventListener('error', logError);
    // eslint-disable-next-line no-console
    console.error(
        'Installing the error handler failed:',
        installError,
        '\nas JSON:',
        JSON.stringify(serializeError(installError))
    );
}
