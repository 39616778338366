import { useCallback, useEffect, useState } from 'preact/hooks';
import { useBeforeunload } from 'react-beforeunload';
import { navigate } from 'wouter-preact/use-location';
import { type Comment, type PullRequest, type PullRequestFile, type Repository } from '../api/code-forge/interface';
import { Accordion } from '../components/accordion';
import { Dropdown } from '../components/dropdown';
import { FlashMessage, flash } from '../components/flash-message';
import { Form } from '../components/form';
import { Markdown } from '../components/markdown';
import { PrLine } from '../components/pr-line';
import { useApi, useListPullRequestComments } from '../hooks/api';
import { prStore } from '../stores/pr';
import { sleep } from '../util/sleep';

const isSupportedPr = (files: PullRequestFile[]) => {
    if (files.length === 0) return 'No files in PR.';
    if (files.some((f) => !f.filename.startsWith('companies/') || !f.filename.endsWith('.json')))
        return 'PR touches non-record files.';
    if (files.some((f) => !['added', 'modified'].includes(f.status))) return 'PR has files with unsupported status.';

    return true;
};
const commentFilter = (c: Comment) => c.user !== 'datenanfragen-bot';

const closureReasons = [
    {
        label: 'B2B only',
        body: "Thanks for the record. But I don't think this company fits our scope. As far as I can tell from the website, they seem to pretty much exclusively target B2B customers, so I doubt they process much personal data of individuals.",
    },
    {
        label: 'Country not in scope',
        body: "Thanks for the record. But the company doesn't seem to meet our inclusion criteria. We are only interested in companies that the GDPR applies to: https://github.com/datenanfragen/data/#companies-we-are-interested-in",
    },
    {
        label: 'No address',
        body: "Thanks for the record! Unfortunately, I was not able to find a source for a postal address for this company and we can only add records with an address. I'll close this now, please feel free to reopen if you (or anyone else) finds a source for an address.",
    },
    {
        label: 'Spam',
        body: "This looks like spam. Please reopen with an explanation if I'm wrong.",
    },
];

export type ReviewPageProps = {
    id: number;
};
export const ReviewPage = (props: ReviewPageProps) => {
    const [pr, setPr] = useState<PullRequest>();
    const [reload, setReload] = useState(true);
    const [isPushing, setIsPushing] = useState(false);
    const [expectedCommitSha, setExpectedCommitSha] = useState<string>();

    const api = useApi();
    const comments = useListPullRequestComments({
        pullRequestNumber: props.id,
        filter: commentFilter,
    });

    useEffect(() => {
        (async () => {
            if (reload && api) {
                // After pushing, the GitHub API tends to take a few seconds to return the updated state, even when
                // disabling the cache, so we do this little dance to ensure we get the correct data, see:
                // https://github.com/datenanfragen/data-editor/issues/1
                let tries = 0;
                let p: PullRequest;
                for (;;) {
                    if (tries > 10)
                        throw new Error(
                            `GitHub API never returned expected commit SHA ${expectedCommitSha} for #${props.id}.`
                        );

                    p = await api.getPullRequest(props.id);

                    if (!expectedCommitSha || p.head.sha === expectedCommitSha) {
                        setPr(p);
                        break;
                    }

                    await sleep(1000 * tries);
                    tries++;
                }

                const baseRepository = p.base.repository;
                const pullRequestFiles =
                    baseRepository.owner && baseRepository.repo
                        ? await api.getPullRequestFiles(props.id, {
                              compareAgainst: {
                                  repository: baseRepository as Repository,
                                  sha: p.base.sha,
                              },
                          })
                        : await api.getPullRequestFiles(props.id);
                if (isSupportedPr(pullRequestFiles) !== true)
                    throw new Error(`Unsupported PR: ${isSupportedPr(pullRequestFiles)}`);

                prStore.set.initWithFiles(pullRequestFiles);
                setReload(false);
            }
        })();
    }, [api, reload, props.id, expectedCommitSha]);

    const hasErrors = prStore.useTracked.hasErrors();
    const hasChanges = prStore.useTracked.hasChanges();

    useBeforeunload(hasChanges ? () => 'You have unsaved changes.' : undefined);

    const pushChanges = useCallback(
        async (options?: { reload?: boolean }) => {
            if (!api) throw new Error('API not initialized.');
            if (hasErrors && !confirm('The record has validation errors. Are you sure you want to push?')) return;

            setIsPushing(true);

            const changedFiles = prStore.get.getChangedFiles();

            const res = await api.commitFilesToPullRequest({
                pullRequestNumber: props.id,
                files: changedFiles.map((f) => ({
                    filename: f.type === 'added' ? f.updatedFilename : f.originalFilename,
                    renamedFilename: f.type === 'added' ? undefined : f.updatedFilename,
                    content: JSON.stringify(f.updatedRecord, null, 4) + '\n',
                })),
            });

            if (
                pr?.labels.includes('via-suggest-api') &&
                changedFiles.length === 1 &&
                changedFiles[0]?.originalRecord?.name !== changedFiles[0]?.updatedRecord?.name
            ) {
                const name = changedFiles[0]?.updatedRecord?.name;

                await api.updatePullRequest({
                    pullRequestNumber: props.id,
                    changes: {
                        title: pr.title.startsWith('Add')
                            ? `Add '${name}' (community contribution)`
                            : `Update '${name}' (community contribution)`,
                    },
                });
            }

            setIsPushing(false);
            flash(<FlashMessage type="success">Pushed changes.</FlashMessage>);
            setExpectedCommitSha(res.commitId);
            if (options?.reload !== false) setReload(true);

            return res;
        },
        [api, props.id, hasErrors, pr]
    );
    const approve = useCallback(
        async (commitId?: string) => {
            if (!api) throw new Error('API not initialized.');
            if (hasErrors) {
                flash(<FlashMessage type="error">Cannot approve PR with validation errors.</FlashMessage>);
                return;
            }

            // Instead of waiting and ensuring that all checks pass, we let GitHub handle that.
            await api.enableAutoMergeForPullRequest(props.id);
            await api.submitPullRequestReview({
                body: 'Thank you!',
                commitId,
                event: 'APPROVE',
                pullRequestNumber: props.id,
            });

            flash(
                <FlashMessage type="success">
                    Approved PR. It will be merged automatically if all checks pass.
                </FlashMessage>
            );
        },
        [api, props.id, hasErrors]
    );

    const closePr = useCallback(
        async (options: { body: string; commitId?: string; confirm?: boolean }) => {
            if (!api) throw new Error('API not initialized.');

            if (
                options.confirm !== false &&
                !confirm('Are you sure you want to close this PR with the following reason?\n\n' + options.body)
            )
                return;

            await api.submitPullRequestReview({
                body: options.body,
                commitId: options.commitId,
                event: 'COMMENT',
                pullRequestNumber: props.id,
            });

            await api.updatePullRequest({ pullRequestNumber: props.id, changes: { state: 'closed' } });

            flash(<FlashMessage type="success">Closed PR.</FlashMessage>);
        },
        [api, props.id]
    );

    if (reload || !pr)
        return expectedCommitSha ? <p>Loading and waiting for GitHub to return the correct data…</p> : <p>Loading…</p>;

    return (
        <>
            <div className="d-flex justify-content-between">
                <PrLine pr={pr} type="review-header" />

                <div className="d-flex align-items-center">
                    <Dropdown
                        color="warning"
                        className="btn-sm"
                        items={[
                            ...closureReasons.map(
                                (r) =>
                                    ({
                                        type: 'button',
                                        children: r.label,
                                        onClick: ({ closeDropdown }: { closeDropdown: () => void }) => {
                                            closePr({ body: r.body });
                                            closeDropdown();
                                        },
                                    } as const)
                            ),

                            { type: 'divider' },

                            {
                                type: 'button',
                                children: 'Custom reason',
                                onClick: ({ closeDropdown: closeDropdown }) => {
                                    const body = prompt('Please enter the reason for closing this PR:');
                                    if (!body) return;

                                    closePr({ body, confirm: false });
                                    closeDropdown();
                                },
                            },
                        ]}>
                        Close PR
                    </Dropdown>

                    <button
                        className="btn btn-sm mx-1 btn-light"
                        disabled={!api || hasChanges || hasErrors}
                        onClick={() => approve()}>
                        Approve
                    </button>
                    <button
                        className="btn btn-sm mx-1 btn-secondary"
                        disabled={!api || !hasChanges || isPushing}
                        onClick={() => pushChanges()}>
                        Push changes
                    </button>
                    <button
                        className="btn btn-sm mx-1 btn-primary"
                        disabled={!api || !hasChanges || isPushing || hasErrors}
                        onClick={async () => {
                            const res = await pushChanges({ reload: false });
                            if (!res) return;
                            await approve(res.commitId);
                            navigate('/');
                        }}>
                        Push changes and approve
                    </button>
                </div>
            </div>

            <Accordion header="Comments and meta" openInitially={comments.length > 0}>
                <div className="row">
                    <div className="col" style="max-height: 500px; overflow: scroll;">
                        {comments.length > 0 ? (
                            comments.map((c) => (
                                <div className="card mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            {c.user}
                                            {c.type === 'approved' && <span className="fw-normal"> approved</span>}
                                            {c.type === 'changes_requested' && (
                                                <span className="fw-normal"> requested changes</span>
                                            )}
                                        </h5>
                                        {c.body && (
                                            <p className="card-text">
                                                <Markdown markdown={c.body} />
                                            </p>
                                        )}

                                        <p className="card-text">
                                            <small className="text-muted">
                                                {new Date(c.createdAt).toLocaleString()}
                                            </small>
                                        </p>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <span className="fst-italic">No comments.</span>
                        )}
                    </div>
                    <div className="col-3">
                        <div className="subheader">Labels</div>
                        {pr.labels.map((l) => (
                            <div className="badge bg-dark me-1">{l}</div>
                        ))}

                        <div className="subheader mt-3">Head</div>
                        <code>
                            {pr.head.repository.owner}/{pr.head.repository.repo}:{pr.head.ref}
                        </code>

                        <div className="subheader mt-3">Base</div>
                        <code>
                            {pr.base.repository.owner}/{pr.base.repository.repo}:{pr.base.ref}
                        </code>
                    </div>
                </div>
            </Accordion>

            {prStore.useTracked.files().map((file) => {
                if (!file) return <p>Loading…</p>;
                if (file.type === 'added') return <>TODO: Support for added files.</>;
                const selector = { existingWithOriginalFilename: file.originalFilename };

                return (
                    <Accordion
                        header={`${file.updatedFilename || file.originalFilename} (${file.pullRequestFile.status})`}
                        openInitially={pr.state === 'draft' || pr.state === 'open'}
                        className="my-3"
                        color={file.pullRequestFile.status === 'modified' ? 'warning' : undefined}>
                        <Form
                            initialData={file.originalRecord}
                            filename={file.updatedFilename || file.originalFilename}
                            file={file}
                            onChange={(formData) => {
                                const filename = formData.record.slug
                                    ? `companies/${formData.record.slug}.json`
                                    : undefined;

                                prStore.set.setForFile(selector, {
                                    updatedRecord: formData.record,
                                    updatedFilename:
                                        filename && filename !== file.originalFilename ? filename : undefined,
                                    validationErrors: formData.validationErrors,
                                });
                            }}
                        />
                    </Accordion>
                );
            })}
        </>
    );
};
