import { navigate, useLocationProperty, type BaseLocationHook } from 'wouter-preact/use-location';

// Plumbing for hash routing, taken from:
// https://github.com/molefrog/wouter/tree/3501053a632d79aa31d9eedca8cd923dba4da941#customizing-the-location-hook
// Necessary because dattel doesn't support rewrites, see #13.
const hashLocation = () => window.location.hash.replace(/^#/, '') || '/';
export const hashNavigate = (to: string) => navigate('#' + to);
export const useHashLocation: BaseLocationHook = () => {
    const location = useLocationProperty(hashLocation);
    return [location, hashNavigate];
};
