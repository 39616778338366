import c from 'classnames';
import { type PullRequest } from '../api/code-forge/interface';
import { Link } from './link';

export type PrLineProps = {
    pr: PullRequest;
    type: 'review-header' | 'home-list';
};

export const PrLine = ({ pr, type }: PrLineProps) => {
    const mergeabilityIcon = pr.mergeable === false && (
        <>
            {' '}
            <i className="bi bi-exclamation-triangle text-danger" title="This PR is not mergeable." />
        </>
    );

    return (
        <div>
            {type === 'review-header' ? (
                <h2>
                    Review “{pr.title}” (
                    <a href={pr.url} target="_blank" rel="noreferrer">
                        #{pr.number}
                    </a>
                    ){mergeabilityIcon}
                </h2>
            ) : (
                <>
                    <Link href={`/review/${pr.number}`}>
                        {pr.title} (#{pr.number})
                    </Link>
                    {mergeabilityIcon}
                </>
            )}

            <div className="text-secondary">
                <ul className="list-inline">
                    <li className="list-inline-item">
                        <span className="visually-hidden">State:</span>
                        <span
                            className={c('badge', {
                                'bg-secondary': pr.state === 'draft',
                                'bg-success': pr.state === 'open',
                                'bg-danger': pr.state === 'closed',
                                'bg-primary': pr.state === 'merged',
                            })}>
                            {pr.state}
                        </span>
                    </li>
                    {pr.user !== undefined && (
                        <li className="list-inline-item">
                            <i className="bi bi-person" title="Opened by" /> {pr.user}
                        </li>
                    )}
                    <li className="list-inline-item">
                        <i className="bi bi-clock" title="Created at" />{' '}
                        {new Date(pr.createdAt).toLocaleString(undefined, {
                            dateStyle: 'long',
                            timeStyle: 'medium',
                        })}
                    </li>
                    {pr.assignees.length > 0 && (
                        <li className="list-inline-item">
                            <i className="bi bi-person-check" title="Assignee(s)" /> {pr.assignees.join(', ')}
                        </li>
                    )}
                    {pr.numComments !== undefined && (
                        <li className="list-inline-item">
                            <i className="bi bi-chat-left" title="Number of comments" /> {pr.numComments}
                        </li>
                    )}
                </ul>
            </div>
        </div>
    );
};
