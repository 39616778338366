/* eslint-disable camelcase */
import { Octokit } from 'octokit';
import { version } from '../../../package.json';
import type { CodeForgeInterface, CommitFile, PullRequest, Repository } from './interface';

const owner = process.env['GITHUB_OWNER'];
const repo = process.env['GITHUB_REPO'];

export const makeApi = async (token: string | undefined): Promise<CodeForgeInterface | undefined> => {
    if (!token) return undefined;
    if (!owner || !repo) throw new Error('GITHUB_OWNER and GITHUB_REPO must be set as environment variables.');

    const octokit = new Octokit({
        auth: token,
        userAgent: `Datenanfragen.de data editor/${version} (https://github.com/datenanfragen/data-editor)`,
    });

    const user = await octokit.rest.users
        .getAuthenticated()
        .then((r) => r.data)
        .then((r) => ({ name: r.login, avatarUrl: r.avatar_url }))
        .catch(() => undefined);
    if (!user) return undefined;

    const canonicalizePullRequest = (
        pr:
            | Awaited<ReturnType<(typeof octokit.rest.pulls)['get']>>['data']
            | Awaited<ReturnType<(typeof octokit.rest.pulls)['list']>>['data'][number]
    ): PullRequest => ({
        number: pr.number,
        title: pr.title,
        body: pr.body || undefined,
        url: pr.html_url,
        diffUrl: pr.diff_url,
        state: pr.draft ? 'draft' : pr.merged_at ? 'merged' : (pr.state as 'open'),
        mergeable: ('mergeable' in pr ? pr.mergeable : undefined) ?? undefined,
        user: pr.user?.login,
        labels: pr.labels.map((l) => l.name),
        createdAt: new Date(pr.created_at),
        updatedAt: new Date(pr.updated_at),
        assignees: pr.assignees?.map((a) => a.login) || [],
        numComments: 'comments' in pr ? pr.comments + pr.review_comments : undefined,

        head: {
            repository: {
                owner: pr.head.repo?.owner.login,
                repo: pr.head.repo?.name,
            },
            ref: pr.head.ref,
            sha: pr.head.sha,
        },
        base: {
            repository: {
                owner: pr.base.repo.owner.login,
                repo: pr.base.repo.name,
            },
            ref: pr.base.ref,
            sha: pr.base.sha,
        },
        maintainerCanModify: 'maintainer_can_modify' in pr ? pr.maintainer_can_modify : undefined,
    });

    const commitFilesToBranch = async ({
        repository,
        branchName,
        files,
    }: {
        repository: Repository;
        branchName: string;
        files: CommitFile[];
    }) => {
        const branch = await octokit.rest.repos.getBranch({
            ...repository,
            branch: branchName,
            headers: { 'If-None-Match': '' },
        });

        const baseSha = branch.data.commit.sha;

        const filesWithIdenticalName = files.filter((f) => f.renamedFilename === undefined);
        const renamedFiles = files.filter((f) => f.renamedFilename !== undefined);
        const newTree = await octokit.rest.git.createTree({
            ...repository,
            base_tree: baseSha,
            tree: [
                ...filesWithIdenticalName.map((f) => ({
                    path: f.filename,
                    mode: '100644' as const,
                    type: 'blob' as const,
                    content: f.content,
                })),
                // See: https://stackoverflow.com/a/72726316
                ...renamedFiles
                    .map((f) => [
                        // Delete the old file.
                        {
                            path: f.filename,
                            mode: '100644' as const,
                            type: 'blob' as const,
                            sha: null,
                        },
                        // Create the new file.
                        {
                            path: f.renamedFilename,
                            mode: '100644' as const,
                            type: 'blob' as const,
                            content: f.content,
                        },
                    ])
                    .flat(),
            ],
        });

        const commit = await octokit.rest.git.createCommit({
            ...repository,
            message: 'Update using data editor',
            tree: newTree.data.sha,
            parents: [baseSha],
        });

        await octokit.rest.git.updateRef({
            ...repository,
            ref: `heads/${branchName}`,
            sha: commit.data.sha,
        });

        return { commitId: commit.data.sha };
    };

    const api: CodeForgeInterface = {
        type: 'github-octokit',

        repository: { owner, repo },
        user,

        listPullRequests: async (options) => {
            const { data: prs, headers } = await octokit.rest.pulls.list({
                owner,
                repo,
                state: options?.state === undefined ? 'all' : options.state,
                sort: options?.sortBy,
                direction: options?.sortDirection,
                per_page: options?.perPage,
                page: options?.page,
            });

            return {
                pullRequests: prs.map(canonicalizePullRequest),
                hasNext: headers.link?.includes('rel="next"') || false,
                hasPrev: headers.link?.includes('rel="prev"') || false,
            };
        },
        getPullRequest: async (pullRequestNumber) => {
            const { data: pr } = await octokit.rest.pulls.get({
                owner,
                repo,
                pull_number: pullRequestNumber,
                headers: { 'If-None-Match': '' },
            });

            if (!pr.head.repo) throw new Error('Pull request does not have a head repository.');

            return canonicalizePullRequest(pr);
        },
        getPullRequestFiles: async (pullRequestNumber, options) => {
            const data = await octokit.rest.pulls
                .listFiles({
                    owner,
                    repo,
                    pull_number: pullRequestNumber,
                    headers: { 'If-None-Match': '' },
                })
                .then((r) => r.data);

            return Promise.all(
                data.map(async (f) => ({
                    filename: f.filename,
                    status: f.status,
                    // Oh, how incredibly silly. The response has a `raw_url` field, but that only redirects to the
                    // correct URL and—unlike everything else—it sets an explicit CORS header that prevents us from
                    // accessing it. So, we have to manually construct the URL. -.-
                    content: await fetch(
                        `https://raw.githubusercontent.com/${owner}/${repo}/${
                            f.raw_url.match(/https:\/\/.+?\/.+?\/raw\/([0-9a-f]+?)\/.+/)?.[1]
                        }/${f.filename}`
                    ).then((r) => r.text()),
                    baseContent:
                        f.status === 'modified' && options?.compareAgainst
                            ? await fetch(
                                  `https://raw.githubusercontent.com/${options.compareAgainst.repository.owner}/${options.compareAgainst.repository.repo}/${options.compareAgainst.sha}/${f.filename}`
                              ).then((r) => r.text())
                            : // I tried doing this properly but just kept wasting more and more time.
                              (undefined as unknown as string),
                }))
            );
        },
        getPullRequestComments: async (pullRequestNumber) => {
            const issueComments = await octokit.rest.issues
                .listComments({
                    owner,
                    repo,
                    issue_number: pullRequestNumber,
                })
                .then((r) => r.data);
            const reviews = await octokit.rest.pulls
                .listReviews({
                    owner,
                    repo,
                    pull_number: pullRequestNumber,
                })
                .then((r) => r.data);
            const reviewComments = await octokit.rest.pulls
                .listReviewComments({
                    owner,
                    repo,
                    pull_number: pullRequestNumber,
                })
                .then((r) => r.data);

            return [...issueComments, ...reviews, ...reviewComments]
                .map((c) => ({ ...c, created_at: 'created_at' in c ? c.created_at : c.submitted_at || '1970-01-01' }))
                .sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())
                .map((c) => ({
                    body: c.body,
                    user: c.user?.login,
                    createdAt: new Date(c.created_at),
                    type: 'state' in c ? (c.state.toLowerCase() as 'approved') : 'comment',
                }));
        },

        canPushToRepository: async (repository) => {
            const { data } = await octokit.rest.repos
                .getCollaboratorPermissionLevel({
                    owner: repository.owner,
                    repo: repository.repo,
                    username: user.name,
                })
                // The call fails if the user does not have push access.
                .catch(() => ({ data: { permission: 'none' } }));

            return data.permission === 'admin' || data.permission === 'write';
        },

        commitFilesToPullRequest: async ({ pullRequestNumber, files }) => {
            const pr = await api.getPullRequest(pullRequestNumber);

            if (!pr.head.repository.owner || !pr.head.repository.repo)
                throw new Error('Pull request does not have a head repository.');

            if (pr.maintainerCanModify !== true && !(await api.canPushToRepository(pr.head.repository as Repository)))
                throw new Error('You cannot commit to this PR.');

            return commitFilesToBranch({
                repository: pr.head.repository as Repository,
                branchName: pr.head.ref,
                files,
            });
        },

        updatePullRequest: async ({ pullRequestNumber, changes }) => {
            await octokit.rest.pulls.update({
                owner,
                repo,
                pull_number: pullRequestNumber,
                ...changes,
            });
        },
        submitPullRequestReview: async ({ pullRequestNumber, commitId, event, body }) => {
            await octokit.rest.pulls.createReview({
                owner,
                repo,
                pull_number: pullRequestNumber,
                commit_id: commitId,
                event,
                body,
            });
        },
        enableAutoMergeForPullRequest: async (pullRequestNumber) => {
            // Annoyingly, the GraphQL API is the only way to enable auto-merge, see:
            // https://stackoverflow.com/a/72259998/3211062.
            const {
                repository: {
                    pullRequest: { id: pullRequestId },
                },
            } = (await octokit.graphql(
                `
query ($owner: String!, $repo: String!, $pullRequestNumber: Int!) {
  repository(owner: $owner, name: $repo) {
    pullRequest(number: $pullRequestNumber) {
      id
    }
  }
}`,
                { owner, repo, pullRequestNumber: +pullRequestNumber }
            )) as { repository: { pullRequest: { id: string } } };

            await octokit.graphql(
                `
mutation ($pullRequestId: ID!) {
  enablePullRequestAutoMerge(
    input: {
      pullRequestId: $pullRequestId
      commitBody: ""
      mergeMethod: SQUASH
    }
  ) {
    clientMutationId
  }
}`,
                { pullRequestId }
            );
        },
    };
    return api;
};
/* eslint-enable camelcase */
