import './error-handler';

import { render } from 'preact';
import { Route, Router, Switch } from 'wouter-preact';
import { Header } from './components/header';
import { HomePage } from './routes/home';
import { ReviewPage } from './routes/review';
import { SettingsPage } from './routes/settings';
import { useHashLocation } from './util/router';

const App = (
    <>
        <Header />

        <main className="container">
            <Router hook={useHashLocation}>
                <Switch>
                    <Route path="/">
                        <HomePage />
                    </Route>
                    <Route path="/review/:id">{(params) => <ReviewPage id={+params.id} />}</Route>
                    <Route path="/settings">
                        <SettingsPage />
                    </Route>
                </Switch>
            </Router>
        </main>

        <div className="container">
            <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
                <p className="col-md-4 mb-0 text-muted">
                    <a href="https://github.com/datenanfragen/data-editor">Source code</a> on GitHub
                </p>

                <ul className="nav col-md-4 justify-content-end">
                    <li className="nav-item">
                        <a href="https://www.datarequests.org/privacy" className="nav-link px-2 text-muted">
                            Privacy policy
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="https://www.datarequests.org/contact" className="nav-link px-2 text-muted">
                            Legal notice and contact
                        </a>
                    </li>
                </ul>
            </footer>
        </div>
    </>
);

const elem = document.getElementById('app');
if (elem) render(App, elem);
