import { createStore } from '@udecode/zustood';
import { type ValidationError } from 'formj';
import { type PullRequestFile, type PullRequestFileWithBaseContent } from '../api/code-forge/interface';
import { type CompanyRecord } from '../dade-data/schema';

export type File =
    | {
          type: 'existing';

          originalFilename: string;
          originalRecord: Partial<CompanyRecord>;

          updatedFilename: string | undefined;
          updatedRecord: Partial<CompanyRecord> | undefined;

          validationErrors: ValidationError[];
          pullRequestFile: PullRequestFile | PullRequestFileWithBaseContent;
      }
    | {
          type: 'added';

          originalFilename: undefined;
          originalRecord: undefined;

          updatedFilename: string;
          updatedRecord: Partial<CompanyRecord>;

          validationErrors: ValidationError[];
          pullRequestFile: PullRequestFile;
      };
export type FileSelector = { existingWithOriginalFilename: string };

export const prStore = createStore('pr')(
    {
        files: [] as File[],
    },
    { devtools: { enabled: process.env['NODE_ENV'] === 'development' } }
)
    .extendSelectors((state) => ({
        getFile: (selector: FileSelector) => {
            if ('existingWithOriginalFilename' in selector)
                return state.files.find(
                    (f) => f.type === 'existing' && f.originalFilename === selector.existingWithOriginalFilename
                );

            throw new Error('Invalid selector');
        },

        getChangedFiles: () =>
            state.files.filter(
                (f) =>
                    (f.updatedFilename !== undefined && f.originalFilename !== f.updatedFilename) ||
                    (f.updatedRecord !== undefined &&
                        JSON.stringify(f.originalRecord) !== JSON.stringify(f.updatedRecord))
            ),
    }))
    .extendSelectors((_state, get) => ({
        hasChanges: () => get.getChangedFiles().length > 0,
        hasErrors: () => _state.files.some((f) => f.validationErrors.length > 0),
    }))
    .extendActions((set) => ({
        initWithFiles: (files: (PullRequestFile | PullRequestFileWithBaseContent)[]) =>
            set.files(
                files.map((f) => ({
                    type: 'existing',

                    originalFilename: f.filename,
                    originalRecord: JSON.parse(f.content),

                    updatedFilename: undefined,
                    updatedRecord: undefined,

                    validationErrors: [],
                    pullRequestFile: f,
                }))
            ),

        setForFile: (selector: FileSelector, changes: Partial<File>) => {
            if ('existingWithOriginalFilename' in selector) {
                set.state((draft) => {
                    const file = draft.files.find(
                        (f) => f.type === 'existing' && f.originalFilename === selector.existingWithOriginalFilename
                    );
                    if (!file) throw new Error('File not found');

                    Object.assign(file, changes);
                });
            } else throw new Error('Invalid selector');
        },
    }));
