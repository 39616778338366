// pkg/dist-src/index.js
import { Octokit as OctokitCore } from "@octokit/core";
import { createAppAuth as createAppAuth3 } from "@octokit/auth-app";
import { OAuthApp } from "@octokit/oauth-app";
import { Webhooks as Webhooks2 } from "@octokit/webhooks";

// pkg/dist-src/version.js
var VERSION = "13.1.8";

// pkg/dist-src/webhooks.js
import { Octokit } from "@octokit/core";
import { createAppAuth } from "@octokit/auth-app";
import { createUnauthenticatedAuth } from "@octokit/auth-unauthenticated";
import { Webhooks } from "@octokit/webhooks";
function webhooks(appOctokit, options) {
  return new Webhooks({
    secret: options.secret,
    transform: async (event) => {
      if (!("installation" in event.payload) || typeof event.payload.installation !== "object") {
        const octokit2 = new appOctokit.constructor({
          authStrategy: createUnauthenticatedAuth,
          auth: {
            reason: `"installation" key missing in webhook event payload`
          }
        });
        return {
          ...event,
          octokit: octokit2
        };
      }
      const installationId = event.payload.installation.id;
      const octokit = await appOctokit.auth({
        type: "installation",
        installationId,
        factory(auth) {
          return new auth.octokit.constructor({
            ...auth.octokitOptions,
            authStrategy: createAppAuth,
            ...{
              auth: {
                ...auth,
                installationId
              }
            }
          });
        }
      });
      octokit.hook.before("request", (options2) => {
        options2.headers["x-github-delivery"] = event.id;
      });
      return {
        ...event,
        octokit
      };
    }
  });
}

// pkg/dist-src/each-installation.js
import { composePaginateRest } from "@octokit/plugin-paginate-rest";
import { Octokit as Octokit3 } from "@octokit/core";

// pkg/dist-src/get-installation-octokit.js
import { createAppAuth as createAppAuth2 } from "@octokit/auth-app";
import { Octokit as Octokit2 } from "@octokit/core";
async function getInstallationOctokit(app, installationId) {
  return app.octokit.auth({
    type: "installation",
    installationId,
    factory(auth) {
      const options = {
        ...auth.octokitOptions,
        authStrategy: createAppAuth2,
        ...{ auth: { ...auth, installationId } }
      };
      return new auth.octokit.constructor(options);
    }
  });
}

// pkg/dist-src/each-installation.js
function eachInstallationFactory(app) {
  return Object.assign(eachInstallation.bind(null, app), {
    iterator: eachInstallationIterator.bind(null, app)
  });
}
async function eachInstallation(app, callback) {
  const i = eachInstallationIterator(app)[Symbol.asyncIterator]();
  let result = await i.next();
  while (!result.done) {
    await callback(result.value);
    result = await i.next();
  }
}
function eachInstallationIterator(app) {
  return {
    async *[Symbol.asyncIterator]() {
      const iterator = composePaginateRest.iterator(
        app.octokit,
        "GET /app/installations"
      );
      for await (const { data: installations } of iterator) {
        for (const installation of installations) {
          const installationOctokit = await getInstallationOctokit(
            app,
            installation.id
          );
          yield { octokit: installationOctokit, installation };
        }
      }
    }
  };
}

// pkg/dist-src/each-repository.js
import { composePaginateRest as composePaginateRest2 } from "@octokit/plugin-paginate-rest";
import { Octokit as Octokit4 } from "@octokit/core";
function eachRepositoryFactory(app) {
  return Object.assign(eachRepository.bind(null, app), {
    iterator: eachRepositoryIterator.bind(null, app)
  });
}
async function eachRepository(app, queryOrCallback, callback) {
  const i = eachRepositoryIterator(
    app,
    callback ? queryOrCallback : void 0
  )[Symbol.asyncIterator]();
  let result = await i.next();
  while (!result.done) {
    if (callback) {
      await callback(result.value);
    } else {
      await queryOrCallback(result.value);
    }
    result = await i.next();
  }
}
function singleInstallationIterator(app, installationId) {
  return {
    async *[Symbol.asyncIterator]() {
      yield {
        octokit: await app.getInstallationOctokit(installationId)
      };
    }
  };
}
function eachRepositoryIterator(app, query) {
  return {
    async *[Symbol.asyncIterator]() {
      const iterator = query ? singleInstallationIterator(app, query.installationId) : app.eachInstallation.iterator();
      for await (const { octokit } of iterator) {
        const repositoriesIterator = composePaginateRest2.iterator(
          octokit,
          "GET /installation/repositories"
        );
        for await (const { data: repositories } of repositoriesIterator) {
          for (const repository of repositories) {
            yield { octokit, repository };
          }
        }
      }
    }
  };
}

// pkg/dist-src/middleware/node/index.js
import { createNodeMiddleware as oauthNodeMiddleware } from "@octokit/oauth-app";
import { createNodeMiddleware as webhooksNodeMiddleware } from "@octokit/webhooks";

// pkg/dist-src/middleware/node/on-unhandled-request-default.js
function onUnhandledRequestDefault(request, response) {
  response.writeHead(404, {
    "content-type": "application/json"
  });
  response.end(
    JSON.stringify({
      error: `Unknown route: ${request.method} ${request.url}`
    })
  );
}

// pkg/dist-src/middleware/node/index.js
function noop() {
}
function createNodeMiddleware(app, options = {}) {
  const log = Object.assign(
    {
      debug: noop,
      info: noop,
      warn: console.warn.bind(console),
      error: console.error.bind(console)
    },
    options.log
  );
  const optionsWithDefaults = {
    onUnhandledRequest: onUnhandledRequestDefault,
    pathPrefix: "/api/github",
    ...options,
    log
  };
  const webhooksMiddleware = webhooksNodeMiddleware(app.webhooks, {
    path: optionsWithDefaults.pathPrefix + "/webhooks",
    log,
    onUnhandledRequest: optionsWithDefaults.onUnhandledRequest
  });
  const oauthMiddleware = oauthNodeMiddleware(app.oauth, {
    pathPrefix: optionsWithDefaults.pathPrefix + "/oauth",
    onUnhandledRequest: optionsWithDefaults.onUnhandledRequest
  });
  return middleware.bind(null, optionsWithDefaults, {
    webhooksMiddleware,
    oauthMiddleware
  });
}
async function middleware(options, { webhooksMiddleware, oauthMiddleware }, request, response, next) {
  const { pathname } = new URL(request.url, "http://localhost");
  if (pathname === `${options.pathPrefix}/webhooks`) {
    return webhooksMiddleware(request, response, next);
  }
  if (pathname.startsWith(`${options.pathPrefix}/oauth/`)) {
    return oauthMiddleware(request, response, next);
  }
  const isExpressMiddleware = typeof next === "function";
  if (isExpressMiddleware) {
    return next();
  }
  return options.onUnhandledRequest(request, response);
}

// pkg/dist-src/index.js
var App = class {
  static defaults(defaults) {
    const AppWithDefaults = class extends this {
      constructor(...args) {
        super({
          ...defaults,
          ...args[0]
        });
      }
    };
    return AppWithDefaults;
  }
  constructor(options) {
    const Octokit5 = options.Octokit || OctokitCore;
    const authOptions = Object.assign(
      {
        appId: options.appId,
        privateKey: options.privateKey
      },
      options.oauth ? {
        clientId: options.oauth.clientId,
        clientSecret: options.oauth.clientSecret
      } : {}
    );
    this.octokit = new Octokit5({
      authStrategy: createAppAuth3,
      auth: authOptions,
      log: options.log
    });
    this.log = Object.assign(
      {
        debug: () => {
        },
        info: () => {
        },
        warn: console.warn.bind(console),
        error: console.error.bind(console)
      },
      options.log
    );
    if (options.webhooks) {
      this.webhooks = webhooks(this.octokit, options.webhooks);
    } else {
      Object.defineProperty(this, "webhooks", {
        get() {
          throw new Error("[@octokit/app] webhooks option not set");
        }
      });
    }
    if (options.oauth) {
      this.oauth = new OAuthApp({
        ...options.oauth,
        clientType: "github-app",
        Octokit: Octokit5
      });
    } else {
      Object.defineProperty(this, "oauth", {
        get() {
          throw new Error(
            "[@octokit/app] oauth.clientId / oauth.clientSecret options are not set"
          );
        }
      });
    }
    this.getInstallationOctokit = getInstallationOctokit.bind(
      null,
      this
    );
    this.eachInstallation = eachInstallationFactory(
      this
    );
    this.eachRepository = eachRepositoryFactory(
      this
    );
  }
};
App.VERSION = VERSION;
export {
  App,
  createNodeMiddleware
};
