import { useState } from 'preact/hooks';
import { Link } from '../components/link';
import { PrLine } from '../components/pr-line';
import { useApi, useListPullRequests } from '../hooks/api';

export const HomePage = () => {
    const api = useApi();

    const [page, setPage] = useState(1);
    const { pullRequests, hasNext, hasPrev, loading } = useListPullRequests({
        state: 'open',
        sortBy: 'created',
        sortDirection: 'asc',
        page,
        perPage: 20,
    });

    return (
        <>
            <h2>Home</h2>

            {api?.user === undefined && (
                <div className="alert alert-danger" role="alert">
                    You are not logged in. While{' '}
                    <a href="https://github.com/datenanfragen/data-editor/issues/3">
                        support for anonymous and non-contributor use is planned
                    </a>
                    , data editor currently only works if you are <strong>logged in and have write access</strong> to
                    the <code>datenanfragen/data</code> and <code>datenanfragen-community-edits/data</code>{' '}
                    repositories. If you are not logged in, the site will currently just break.
                    <br />
                    Please head to the <Link href="/settings">settings page</Link> and specify a token.
                </div>
            )}

            <p>
                Data editor is a tool to help us maintain the{' '}
                <a href="https://github.com/datenanfragen/data">Datenanfragen.de company database</a>. Currently, only
                support for reviewing pull requests is implemented, but in the future, you will also be able to{' '}
                <a href="https://github.com/datenanfragen/data-editor/issues/6">add new company records</a> and{' '}
                <a href="https://github.com/datenanfragen/data-editor/issues/7">update existing ones</a>.
            </p>

            <div className="alert alert-warning" role="alert">
                Data editor is still in an alpha state and under active development.{' '}
                <strong>
                    Expect bugs, edge cases not accounted for, and missing features. Data loss is also possible,
                </strong>{' '}
                so don’t do too much work without pushing your changes.
                <br />
                Please liberally report bugs you encounter as well as any features you would like to have or ideas for
                UX improvements you have, preferably through{' '}
                <a href="https://github.com/datenanfragen/data-editor/issues">GitHub issues</a>.
            </div>

            {pullRequests.length > 0 && (
                <>
                    <h3>Pull requests to review</h3>
                    <ul>
                        {pullRequests.map((pr) => (
                            <li>
                                <PrLine pr={pr} type="home-list" />
                            </li>
                        ))}
                    </ul>

                    {hasPrev && (
                        <button
                            disabled={loading}
                            onClick={() => setPage((p) => p - 1)}
                            className="btn btn-sm btn-secondary mx-1">
                            Previous page
                        </button>
                    )}
                    {hasNext && (
                        <button
                            disabled={loading}
                            onClick={() => setPage((p) => p + 1)}
                            className="btn btn-sm btn-primary mx-1">
                            Next page
                        </button>
                    )}
                </>
            )}
        </>
    );
};
