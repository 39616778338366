[
  {
    "scheme": "android-app"
  },
  {
    "scheme": "webpack"
  },
  {
    "scheme": "s3",
    "description": "Amazon Web Services S3 bucket"
  },
  {
    "scheme": "gs",
    "description": "Google Cloud Storage"
  },
  {
    "scheme": "mqtt",
    "description": "Message Queuing Telemetry Transport Protocol"
  },
  {
    "scheme": "modbus+tcp",
    "description": "Modbus over TCP"
  }
]
