// pkg/dist-src/version.js
var VERSION = "2.0.6";

// pkg/dist-src/get-web-flow-authorization-url.js
import { oauthAuthorizationUrl } from "@octokit/oauth-authorization-url";
import { request as defaultRequest } from "@octokit/request";

// pkg/dist-src/utils.js
import { RequestError } from "@octokit/request-error";
function requestToOAuthBaseUrl(request) {
  const endpointDefaults = request.endpoint.DEFAULTS;
  return /^https:\/\/(api\.)?github\.com$/.test(endpointDefaults.baseUrl) ? "https://github.com" : endpointDefaults.baseUrl.replace("/api/v3", "");
}
async function oauthRequest(request, route, parameters) {
  const withOAuthParameters = {
    baseUrl: requestToOAuthBaseUrl(request),
    headers: {
      accept: "application/json"
    },
    ...parameters
  };
  const response = await request(route, withOAuthParameters);
  if ("error" in response.data) {
    const error = new RequestError(
      `${response.data.error_description} (${response.data.error}, ${response.data.error_uri})`,
      400,
      {
        request: request.endpoint.merge(
          route,
          withOAuthParameters
        ),
        headers: response.headers
      }
    );
    error.response = response;
    throw error;
  }
  return response;
}

// pkg/dist-src/get-web-flow-authorization-url.js
function getWebFlowAuthorizationUrl({
  request = defaultRequest,
  ...options
}) {
  const baseUrl = requestToOAuthBaseUrl(request);
  return oauthAuthorizationUrl({
    ...options,
    baseUrl
  });
}

// pkg/dist-src/exchange-web-flow-code.js
import { request as defaultRequest2 } from "@octokit/request";
async function exchangeWebFlowCode(options) {
  const request = options.request || /* istanbul ignore next: we always pass a custom request in tests */
  defaultRequest2;
  const response = await oauthRequest(
    request,
    "POST /login/oauth/access_token",
    {
      client_id: options.clientId,
      client_secret: options.clientSecret,
      code: options.code,
      redirect_uri: options.redirectUrl
    }
  );
  const authentication = {
    clientType: options.clientType,
    clientId: options.clientId,
    clientSecret: options.clientSecret,
    token: response.data.access_token,
    scopes: response.data.scope.split(/\s+/).filter(Boolean)
  };
  if (options.clientType === "github-app") {
    if ("refresh_token" in response.data) {
      const apiTimeInMs = new Date(response.headers.date).getTime();
      authentication.refreshToken = response.data.refresh_token, authentication.expiresAt = toTimestamp(
        apiTimeInMs,
        response.data.expires_in
      ), authentication.refreshTokenExpiresAt = toTimestamp(
        apiTimeInMs,
        response.data.refresh_token_expires_in
      );
    }
    delete authentication.scopes;
  }
  return { ...response, authentication };
}
function toTimestamp(apiTimeInMs, expirationInSeconds) {
  return new Date(apiTimeInMs + expirationInSeconds * 1e3).toISOString();
}

// pkg/dist-src/create-device-code.js
import { request as defaultRequest3 } from "@octokit/request";
async function createDeviceCode(options) {
  const request = options.request || /* istanbul ignore next: we always pass a custom request in tests */
  defaultRequest3;
  const parameters = {
    client_id: options.clientId
  };
  if ("scopes" in options && Array.isArray(options.scopes)) {
    parameters.scope = options.scopes.join(" ");
  }
  return oauthRequest(request, "POST /login/device/code", parameters);
}

// pkg/dist-src/exchange-device-code.js
import { request as defaultRequest4 } from "@octokit/request";
async function exchangeDeviceCode(options) {
  const request = options.request || /* istanbul ignore next: we always pass a custom request in tests */
  defaultRequest4;
  const response = await oauthRequest(
    request,
    "POST /login/oauth/access_token",
    {
      client_id: options.clientId,
      device_code: options.code,
      grant_type: "urn:ietf:params:oauth:grant-type:device_code"
    }
  );
  const authentication = {
    clientType: options.clientType,
    clientId: options.clientId,
    token: response.data.access_token,
    scopes: response.data.scope.split(/\s+/).filter(Boolean)
  };
  if ("clientSecret" in options) {
    authentication.clientSecret = options.clientSecret;
  }
  if (options.clientType === "github-app") {
    if ("refresh_token" in response.data) {
      const apiTimeInMs = new Date(response.headers.date).getTime();
      authentication.refreshToken = response.data.refresh_token, authentication.expiresAt = toTimestamp2(
        apiTimeInMs,
        response.data.expires_in
      ), authentication.refreshTokenExpiresAt = toTimestamp2(
        apiTimeInMs,
        response.data.refresh_token_expires_in
      );
    }
    delete authentication.scopes;
  }
  return { ...response, authentication };
}
function toTimestamp2(apiTimeInMs, expirationInSeconds) {
  return new Date(apiTimeInMs + expirationInSeconds * 1e3).toISOString();
}

// pkg/dist-src/check-token.js
import { request as defaultRequest5 } from "@octokit/request";
import btoa from "btoa-lite";
async function checkToken(options) {
  const request = options.request || /* istanbul ignore next: we always pass a custom request in tests */
  defaultRequest5;
  const response = await request("POST /applications/{client_id}/token", {
    headers: {
      authorization: `basic ${btoa(
        `${options.clientId}:${options.clientSecret}`
      )}`
    },
    client_id: options.clientId,
    access_token: options.token
  });
  const authentication = {
    clientType: options.clientType,
    clientId: options.clientId,
    clientSecret: options.clientSecret,
    token: options.token,
    scopes: response.data.scopes
  };
  if (response.data.expires_at)
    authentication.expiresAt = response.data.expires_at;
  if (options.clientType === "github-app") {
    delete authentication.scopes;
  }
  return { ...response, authentication };
}

// pkg/dist-src/refresh-token.js
import { request as defaultRequest6 } from "@octokit/request";
async function refreshToken(options) {
  const request = options.request || /* istanbul ignore next: we always pass a custom request in tests */
  defaultRequest6;
  const response = await oauthRequest(
    request,
    "POST /login/oauth/access_token",
    {
      client_id: options.clientId,
      client_secret: options.clientSecret,
      grant_type: "refresh_token",
      refresh_token: options.refreshToken
    }
  );
  const apiTimeInMs = new Date(response.headers.date).getTime();
  const authentication = {
    clientType: "github-app",
    clientId: options.clientId,
    clientSecret: options.clientSecret,
    token: response.data.access_token,
    refreshToken: response.data.refresh_token,
    expiresAt: toTimestamp3(apiTimeInMs, response.data.expires_in),
    refreshTokenExpiresAt: toTimestamp3(
      apiTimeInMs,
      response.data.refresh_token_expires_in
    )
  };
  return { ...response, authentication };
}
function toTimestamp3(apiTimeInMs, expirationInSeconds) {
  return new Date(apiTimeInMs + expirationInSeconds * 1e3).toISOString();
}

// pkg/dist-src/scope-token.js
import { request as defaultRequest7 } from "@octokit/request";
import btoa2 from "btoa-lite";
async function scopeToken(options) {
  const {
    request: optionsRequest,
    clientType,
    clientId,
    clientSecret,
    token,
    ...requestOptions
  } = options;
  const request = optionsRequest || /* istanbul ignore next: we always pass a custom request in tests */
  defaultRequest7;
  const response = await request(
    "POST /applications/{client_id}/token/scoped",
    {
      headers: {
        authorization: `basic ${btoa2(`${clientId}:${clientSecret}`)}`
      },
      client_id: clientId,
      access_token: token,
      ...requestOptions
    }
  );
  const authentication = Object.assign(
    {
      clientType,
      clientId,
      clientSecret,
      token: response.data.token
    },
    response.data.expires_at ? { expiresAt: response.data.expires_at } : {}
  );
  return { ...response, authentication };
}

// pkg/dist-src/reset-token.js
import { request as defaultRequest8 } from "@octokit/request";
import btoa3 from "btoa-lite";
async function resetToken(options) {
  const request = options.request || /* istanbul ignore next: we always pass a custom request in tests */
  defaultRequest8;
  const auth = btoa3(`${options.clientId}:${options.clientSecret}`);
  const response = await request(
    "PATCH /applications/{client_id}/token",
    {
      headers: {
        authorization: `basic ${auth}`
      },
      client_id: options.clientId,
      access_token: options.token
    }
  );
  const authentication = {
    clientType: options.clientType,
    clientId: options.clientId,
    clientSecret: options.clientSecret,
    token: response.data.token,
    scopes: response.data.scopes
  };
  if (response.data.expires_at)
    authentication.expiresAt = response.data.expires_at;
  if (options.clientType === "github-app") {
    delete authentication.scopes;
  }
  return { ...response, authentication };
}

// pkg/dist-src/delete-token.js
import { request as defaultRequest9 } from "@octokit/request";
import btoa4 from "btoa-lite";
async function deleteToken(options) {
  const request = options.request || /* istanbul ignore next: we always pass a custom request in tests */
  defaultRequest9;
  const auth = btoa4(`${options.clientId}:${options.clientSecret}`);
  return request(
    "DELETE /applications/{client_id}/token",
    {
      headers: {
        authorization: `basic ${auth}`
      },
      client_id: options.clientId,
      access_token: options.token
    }
  );
}

// pkg/dist-src/delete-authorization.js
import { request as defaultRequest10 } from "@octokit/request";
import btoa5 from "btoa-lite";
async function deleteAuthorization(options) {
  const request = options.request || /* istanbul ignore next: we always pass a custom request in tests */
  defaultRequest10;
  const auth = btoa5(`${options.clientId}:${options.clientSecret}`);
  return request(
    "DELETE /applications/{client_id}/grant",
    {
      headers: {
        authorization: `basic ${auth}`
      },
      client_id: options.clientId,
      access_token: options.token
    }
  );
}
export {
  VERSION,
  checkToken,
  createDeviceCode,
  deleteAuthorization,
  deleteToken,
  exchangeDeviceCode,
  exchangeWebFlowCode,
  getWebFlowAuthorizationUrl,
  refreshToken,
  resetToken,
  scopeToken
};
