import c from 'classnames';
import { nanoid } from 'nanoid';
import { type ComponentChildren } from 'preact';
import { useState } from 'preact/hooks';
import type { MergeExclusive } from 'type-fest';

export type AccordionProps = {
    id?: string;
    className?: string;
    color?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
    openInitially: boolean;

    children: ComponentChildren;
} & MergeExclusive<{ header: ComponentChildren }, { preHeader?: ComponentChildren }>;

export const Accordion = (props: AccordionProps) => {
    const id = props.id || nanoid();
    const innerId = `${id}-inner`;

    const [open, setOpen] = useState(props.openInitially);

    return (
        <div id={id} className={c('accordion', props.className)}>
            <div className="accordion-item">
                <h2 className="accordion-header">
                    {props.preHeader}
                    <button
                        className={c('accordion-button', {
                            collapsed: !open,
                            ...(props.color ? { [`bg-${props.color}`]: true } : {}),
                        })}
                        type="button"
                        aria-expanded={open}
                        aria-controls={innerId}
                        onClick={() => setOpen(!open)}>
                        {props.header}
                    </button>
                </h2>
                <div id={innerId} className={c('accordion-collapse', 'collapse', { show: open })}>
                    <div className="accordion-body">{props.children}</div>
                </div>
            </div>
        </div>
    );
};
