import slugify from '@sindresorhus/slugify';
import { Form as _Form, type FormApi, type ValidationError, type FormProps as _FormProps } from 'formj';
import { useEffect, useMemo, useRef, useState } from 'preact/hooks';
import { useDebouncedCallback } from 'use-debounce';
import { existingCompanySlugs, existingTemplatesPerLanguage } from '../dade-data/ctx';
import { schema, type CompanyRecord } from '../dade-data/schema';
import { ajv, companyChecks, type CheckInstance } from '../dade-data/validate';
import { useConfigStore } from '../stores/config';
import { type File } from '../stores/pr';
import { formatPhoneNumber } from '../util/phone';
import { Accordion } from './accordion';
import { DiffMethod, ReactDiffViewer } from './diff';
import { Markdown } from './markdown';

export type FormProps = {
    initialData: Partial<CompanyRecord>;
    filename: string;
    file: File;
    showValidationErrors?: boolean;

    formApiRef?: _FormProps<CompanyRecord>['formApiRef'];

    onChange?: (data: { record: Partial<CompanyRecord>; validationErrors: ValidationError[] }) => void;
    onSubmit?: _FormProps<CompanyRecord>['onSubmit'];
};

export const Form = (props: FormProps) => {
    const [record, setRecord] = useState<Partial<CompanyRecord>>();
    const stringifiedRecord = useMemo(() => JSON.stringify(record, null, 4), [record]);
    const [validationErrors, setValidationErrors] = useState<ValidationError[]>();

    const diffView = useConfigStore((s) => s.diffView);

    const formApiRef = useRef<FormApi<CompanyRecord>>(null);

    useEffect(() => {
        if (props.formApiRef) props.formApiRef.current = formApiRef.current;
    }, [props.formApiRef, formApiRef]);

    // When the filename changes, we need to trigger a revalidation because one check depends on the filename.
    useEffect(() => {
        const validationResult = formApiRef.current?.validate() || [];
        setValidationErrors(validationResult === true ? [] : validationResult);

        props.onChange?.({
            record: (formApiRef.current?.get('') || {}) as CompanyRecord,
            validationErrors: validationResult === true ? [] : validationResult,
        });
        // We previously included `onChange` here as the linter suggested but that caused infinite loops that broke the
        // page.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.filename]);

    const debouncedOnChange = useDebouncedCallback(
        ((formData) => {
            setRecord(formData.object);
            const validationResult = formApiRef.current?.validate() || [];
            setValidationErrors(validationResult === true ? [] : validationResult);

            props.onChange?.({
                record: formData.object,
                validationErrors: validationResult === true ? [] : validationResult,
            });
        }) satisfies _FormProps<CompanyRecord>['onChange'],
        500
    );

    return (
        <>
            {stringifiedRecord &&
                'baseContent' in props.file.pullRequestFile &&
                props.file.pullRequestFile.baseContent && (
                    <Accordion header="Diff" openInitially={true} className="mb-3">
                        <ReactDiffViewer
                            oldValue={props.file.pullRequestFile.baseContent}
                            newValue={stringifiedRecord}
                            splitView={diffView === 'split'}
                            hideLineNumbers={true}
                            compareMethod={DiffMethod.WORDS_WITH_SPACE}
                            styles={{ diffContainer: { pre: { lineHeight: 'inherit' } } }}
                        />
                    </Accordion>
                )}

            <div className="row">
                <div className="col">
                    <_Form
                        schema={schema}
                        initialData={props.initialData}
                        showValidationErrors={props.showValidationErrors}
                        autoComplete="off"
                        helpers={[
                            // Format phone number.
                            ({ value, setValue }) => ({
                                pointers: ['/phone', '/fax'],
                                type: 'button',
                                hidden: !value,
                                children: <i className="bi bi-magic" />,
                                attributes: {
                                    title: 'Format number',
                                    onClick: () => {
                                        const formatted = formatPhoneNumber(value);
                                        if (formatted) setValue(formatted);
                                    },
                                },
                            }),
                            ({ value, setValue }) => ({
                                pointers: ['/phone', '/fax'],
                                type: 'event-handler',
                                enabled: !!value,
                                event: 'onKeyDown',
                                handler: (e) => {
                                    if (e.ctrlKey || e.key !== 'Enter') return;

                                    const formatted = formatPhoneNumber(value);
                                    if (formatted) setValue(formatted);
                                },
                            }),

                            // Format/fix phone numbers and email addresses on paste.
                            ({ setValue }) => ({
                                pointers: ['/phone', '/fax'],
                                type: 'event-handler',
                                event: 'onPaste',
                                handler: (e) => {
                                    const pastedValue = e.clipboardData?.getData('text/plain');
                                    if (!pastedValue) return;

                                    const formatted = formatPhoneNumber(pastedValue, {
                                        promptForCountryIfNecessary: false,
                                    });
                                    if (!formatted) return;

                                    // The value is not yet in the input field, so we need to wait for the next tick,
                                    // see: https://stackoverflow.com/a/13895093
                                    setTimeout(() => setValue(formatted), 0);
                                },
                            }),
                            ({ setValue }) => ({
                                pointers: ['/email'],
                                type: 'event-handler',
                                event: 'onPaste',
                                handler: (e) => {
                                    const pastedValue = e.clipboardData?.getData('text/plain');
                                    if (!pastedValue) return;

                                    let formatted = pastedValue.trim();
                                    for (const [obfuscatedChar, char] of [
                                        ['@', '@'],
                                        ['at', '@'],
                                        ['ät', '@'],
                                        ['.', '.'],
                                        ['dot', '.'],
                                        ['punkt', '.'],
                                    ] as const) {
                                        for (const [delimLeft, delimRight] of ['  ', '<>', '()', '[]', '{}']) {
                                            formatted = formatted.replace(
                                                `${delimLeft}${obfuscatedChar}${delimRight}`,
                                                char
                                            );
                                        }
                                    }

                                    setTimeout(() => setValue(formatted), 0);
                                },
                            }),

                            // Set suggested transport medium according to field.
                            ({ value, pointer }) => ({
                                pointers: ['/email', '/fax', '/address', '/webform'],
                                type: 'event-handler',
                                enabled: !!value,
                                event: 'onKeyDown',
                                handler: (e) => {
                                    if (['/fax', '/address'].includes(pointer)) {
                                        if (!e.ctrlKey || e.key !== 'Enter') return;
                                    } else if (e.key !== 'Enter') return;

                                    const medium = pointer.endsWith('address') ? 'letter' : pointer.replace('/', '');
                                    if (confirm(`Set suggested transport medium to ${medium}?`))
                                        formApiRef.current?.set(`/suggested-transport-medium`, medium);
                                },
                            }),

                            // Provide link for link fields.
                            ({ value }) => ({
                                pointers: ['/pgp-url', '/web', '/webform', '/sources/*'],
                                type: 'custom-addon',
                                hidden: !value,
                                element: (
                                    <a href={value as string} title="Open link" target="_blank" rel="noreferrer">
                                        <i className="bi bi-link-45deg" />
                                    </a>
                                ),
                            }),

                            // Suggest slug based on name or website.
                            () => ({
                                pointers: ['/slug'],
                                type: 'suggestions',
                                suggestions: () => {
                                    const suggestions: string[] = [];

                                    const name = formApiRef.current?.get('/name');
                                    if (name && typeof name === 'string') suggestions.push(slugify(name));

                                    const web = formApiRef.current?.get('/web');
                                    if (web && typeof web === 'string') {
                                        try {
                                            suggestions.push(slugify(new URL(web).hostname.replace('www.', '')));
                                        } catch {
                                            // Ignore.
                                        }
                                    }

                                    return suggestions;
                                },
                            }),
                        ]}
                        customAjv={ajv}
                        customValidators={companyChecks.map((c) => (obj: Partial<CompanyRecord>) => {
                            const res = c.run(obj, {
                                // eslint-disable-next-line camelcase
                                file_path: props.filename,
                                // eslint-disable-next-line camelcase
                                file_content: JSON.stringify(obj, null, 4) + '\n',
                                existingCompanySlugs,
                                existingTemplatesPerLanguage,
                            });
                            return (Array.isArray(res) ? res : [res])
                                .filter((r): r is CheckInstance => !!r)
                                .map((r) => ({
                                    message: r.message,
                                    pointer: r.json_pointer ?? '',
                                }));
                        })}
                        onChange={debouncedOnChange}
                        onSubmit={props.onSubmit}
                        formApiRef={formApiRef}
                    />
                </div>

                <div className="col-5">
                    {validationErrors && validationErrors.length > 0 && (
                        <Accordion header="Validation errors" openInitially={true} className="mb-3">
                            <ul>
                                {validationErrors.map((e) => (
                                    <li>
                                        <Markdown markdown={e.message} /> (at <code>{e.pointer}</code>)
                                    </li>
                                ))}
                            </ul>
                        </Accordion>
                    )}

                    <Accordion header="Record" openInitially={true} className="mb-3">
                        <pre>{stringifiedRecord}</pre>
                    </Accordion>
                </div>
            </div>
        </>
    );
};

export type { FormApi };
