// Adapted for hash-based routing and Preact after:
// https://github.com/molefrog/wouter/blob/3501053a632d79aa31d9eedca8cd923dba4da941/index.jsexport
import type { JSX } from 'preact';
import { cloneElement, isValidElement } from 'preact';
import type { Except } from 'type-fest';
import { type LinkProps } from 'wouter-preact';
// @ts-expect-error Wouter is not written in TS.
import { useEvent } from 'wouter-preact/react-deps';
import { hashNavigate } from '../util/router';

export const Link = (props: Except<LinkProps, 'to' | 'ref'>) => {
    const { href, children, onClick } = props;
    if (!href) throw new Error('Either "to" or "href" prop must be specified.');

    const handleClick = useEvent((event: JSX.TargetedMouseEvent<EventTarget>) => {
        if (event.ctrlKey || event.metaKey || event.altKey || event.shiftKey || event.button !== 0) return;

        onClick?.(event);
        if (!event.defaultPrevented) {
            event.preventDefault();
            hashNavigate(href);
        }
    });

    // wraps children in `a` if needed
    const extraProps = {
        href: '/#' + href,
        onClick: handleClick,
    };
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    const jsx = isValidElement(children) ? children : <a {...props} />;

    return cloneElement(jsx, extraProps);
};
