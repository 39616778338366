import { useApi } from '../hooks/api';
import { Link } from './link';

const links = [
    { href: '/', text: 'Home' },
    { href: '/settings', text: 'Settings' },
];

export const Header = () => {
    const api = useApi();

    return (
        <header>
            <nav className="navbar navbar-expand-lg bg-primary-subtle mb-4" data-bs-theme="dark">
                <div className="container">
                    <Link className="navbar-brand" href="/">
                        data editor
                    </Link>

                    <div className="collapse navbar-collapse">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            {links.map(({ href, text }) => (
                                <li className="nav-item">
                                    <Link className="nav-link" href={href}>
                                        {text}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <img
                        className="rounded-circle"
                        width="32"
                        height="32"
                        src={api?.user.avatarUrl}
                        title={api ? `Logged in as ${api.user.name}.` : ''}
                        alt={`GitHub avatar of ${api?.user.name}`}
                    />
                </div>
            </nav>
        </header>
    );
};
