import { type VNode } from 'preact';
import _ReactMarkdown from 'react-markdown';
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize';
import remarkBreaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';
import remarkGithub from 'remark-github';
import type { SetReturnType } from 'type-fest';
import { useApi } from '../hooks/api';

// Annoying type incompatibility between Preact and React…
const ReactMarkdown = _ReactMarkdown as unknown as SetReturnType<typeof _ReactMarkdown, VNode>;

export type MarkdownProps = {
    markdown: string;
};
export const Markdown = (props: MarkdownProps) => {
    const api = useApi();

    if (!api) return <>'Loading…'</>;

    const component = (
        <ReactMarkdown
            children={props.markdown}
            rehypePlugins={[[rehypeSanitize, defaultSchema]]}
            remarkPlugins={[
                remarkBreaks,
                remarkGfm,
                [remarkGithub, { repository: `${api.repository.owner}/${api.repository.repo}` }],
            ]}
        />
    );

    return component;
};
