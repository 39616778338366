import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type ConfigState = {
    githubToken?: string;

    diffView: 'unified' | 'split';

    setGithubToken: (token: string) => void;

    setDiffView: (view: 'unified' | 'split') => void;
};

export const useConfigStore = create<ConfigState>()(
    persist(
        (set) => ({
            githubToken: undefined,
            diffView: 'split',

            setGithubToken: (token: string) => set({ githubToken: token }),

            setDiffView: (view: 'unified' | 'split') => set({ diffView: view }),
        }),
        { name: 'data-editor-config', version: 1 }
    )
);
