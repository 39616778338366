import type { ComponentChildren, h } from 'preact';
import { render } from 'preact';
import { useEffect, useState } from 'preact/hooks';

type FlashMessageProps = {
    type: 'info' | 'warning' | 'error' | 'success';
    duration?: number | null;
    children: ComponentChildren;
};

export const FlashMessage = (props: FlashMessageProps) => {
    const type = props.type === 'error' ? 'danger' : props.type;
    const [shown, setShown] = useState(true);

    const duration = props.duration === undefined ? 5000 : props.duration;

    useEffect(() => {
        if (duration) setTimeout(() => setShown(false), duration);
    });

    return shown ? (
        <div
            className={`toast align-items-center show bg-${type}-subtle`}
            role="alert"
            aria-live="assertive"
            aria-atomic="true">
            <div className="d-flex">
                <div className="toast-body">{props.children}</div>
                <button
                    type="button"
                    className="btn-close me-2 m-auto"
                    onClick={() => setShown(false)}
                    aria-label="Close"
                />
            </div>
        </div>
    ) : (
        <></>
    );
};

const flashMessagesEl = document.getElementById('flash-messages');
const flashMessages: h.JSX.Element[] = [];

export const flash = (flashMessage: h.JSX.Element) => {
    flashMessages.push(flashMessage);
    if (flashMessagesEl) render(<>{flashMessages}</>, flashMessagesEl);
};
