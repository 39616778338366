{
    "name": "data-editor",
    "version": "0.0.0",
    "private": true,
    "description": "A UI for editing records and reviewing PRs in the Datenanfragen.de company database.",
    "bugs": "https://github.com/datenanfragen/data-editor/issues",
    "repository": {
        "type": "git",
        "url": "https://github.com/datenanfragen/data-editor.git"
    },
    "license": "MIT",
    "author": "Benjamin Altpeter <hi@bn.al>",
    "type": "module",
    "source": "src/index.html",
    "scripts": {
        "build": "yarn clean && yarn tsx dependencies.ts && parcel build",
        "clean": "rm -rf .parcel-cache dist",
        "fix": "yarn eslint . --ignore-path .gitignore --ext .js,.jsx,.ts,.tsx --fix",
        "lint": "tsc && eslint . --ignore-path .gitignore --ext .js,.jsx,.ts,.tsx && git diff --check",
        "prepack": "yarn build",
        "test": "echo 'TODO: No tests specified yet.'",
        "watch": "yarn clean && parcel"
    },
    "husky": {
        "hooks": {
            "pre-commit": "lint-staged && tsc"
        }
    },
    "lint-staged": {
        "*.{ts,js,tsx,jsx,css,scss,json}": [
            "prettier --write"
        ],
        "*.{ts,js,tsx,jsx}": [
            "eslint --fix"
        ]
    },
    "browserslist": "> 0.5%, last 2 versions, not dead",
    "prettier": "@baltpeter/prettier-config",
    "dependencies": {
        "@sindresorhus/slugify": "^2.2.1",
        "@udecode/zustood": "^2.0.0",
        "ajv": "^8.12.0",
        "ajv-formats": "^2.1.1",
        "ajv-formats-draft2019": "^1.6.1",
        "all-the-cities": "^3.1.0",
        "bootstrap": "^5.3.1",
        "bootstrap-icons": "^1.10.5",
        "countries-list": "^2.6.1",
        "formj": "file:.yalc/formj",
        "globby": "^13.2.2",
        "libphonenumber-js": "^1.10.39",
        "nanoid": "^4.0.2",
        "octokit": "^2.0.14",
        "octokit-plugin-create-pull-request": "4.1.1",
        "preact": "^10.13.1",
        "react-beforeunload": "^2.6.0",
        "react-diff-viewer": "^3.1.1",
        "react-markdown": "^8.0.7",
        "rehype-sanitize": "^6.0.0",
        "remark-breaks": "^3.0.3",
        "remark-gfm": "^3.0.1",
        "remark-github": "^11.2.4",
        "scheduler": "^0.23.0",
        "serialize-error": "^11.0.2",
        "use-debounce": "^9.0.4",
        "wouter-preact": "^2.11.0",
        "zustand": "^4.4.1"
    },
    "devDependencies": {
        "@baltpeter/eslint-config": "^2.2.0",
        "@baltpeter/prettier-config": "2.0.0",
        "@baltpeter/tsconfig": "3.0.0",
        "@parcel/packager-ts": "2.8.3",
        "@parcel/transformer-sass": "2.8.3",
        "@parcel/transformer-typescript-types": "2.8.3",
        "@types/all-the-cities": "^3.1.0",
        "@types/json-schema": "^7.0.12",
        "@types/react-beforeunload": "^2.1.1",
        "@typescript-eslint/eslint-plugin": "5.56.0",
        "assert": "^2.0.0",
        "buffer": "^5.5.0",
        "eslint": "8.36.0",
        "eslint-plugin-eslint-comments": "3.2.0",
        "eslint-plugin-import": "2.27.5",
        "eslint-plugin-jsx-a11y": "^6.7.1",
        "eslint-plugin-react": "^7.33.2",
        "eslint-plugin-react-hooks": "^4.6.0",
        "events": "^3.1.0",
        "execa": "^7.2.0",
        "husky": "4.3.7",
        "lint-staged": "13.2.0",
        "parcel": "2.8.3",
        "path-browserify": "^1.0.0",
        "prettier": "2.8.6",
        "process": "^0.11.10",
        "punycode": "^1.4.1",
        "tsx": "^3.12.7",
        "type-fest": "^4.2.0",
        "typescript": "5.1.6",
        "util": "^0.12.3"
    },
    "alias": {
        "react": "preact/compat",
        "react-dom/test-utils": "preact/test-utils",
        "react-dom": "preact/compat",
        "react/jsx-runtime": "preact/jsx-runtime"
    }
}
