import { useEffect, useState } from 'preact/hooks';
import { makeApi } from '../api/code-forge/github-octokit';
import type { CodeForgeInterface, Comment } from '../api/code-forge/interface';
import { useConfigStore } from '../stores/config';

export const useApi = () => {
    const configStore = useConfigStore();
    const [api, setApi] = useState<CodeForgeInterface | undefined>(undefined);

    useEffect(() => {
        if (configStore.githubToken) makeApi(configStore.githubToken).then((a) => setApi(a));
        else setApi(undefined);
    }, [configStore.githubToken]);

    return api;
};

export const useListPullRequests = (options?: Parameters<CodeForgeInterface['listPullRequests']>[0]) => {
    const api = useApi();
    const [loading, setLoading] = useState(false);
    const [res, setRes] = useState<Awaited<ReturnType<CodeForgeInterface['listPullRequests']>>>({
        pullRequests: [],
        hasNext: false,
        hasPrev: false,
    });

    const { page, perPage, sortBy, sortDirection, state } = options || {};

    useEffect(() => {
        if (!api) return;

        setLoading(true);
        api.listPullRequests({ page, perPage, sortBy, sortDirection, state }).then((r) => {
            setRes(r);
            setLoading(false);
        });
    }, [api, page, perPage, sortBy, sortDirection, state]);

    return { ...res, loading };
};

export const useListPullRequestComments = (options: {
    pullRequestNumber: number;
    filter?: (comment: Comment) => boolean;
}) => {
    const api = useApi();
    const [res, setRes] = useState<Comment[]>([]);

    useEffect(() => {
        if (!api) return;

        api.getPullRequestComments(options.pullRequestNumber).then((r) =>
            setRes(options.filter ? r.filter(options.filter) : r)
        );
    }, [api, options.filter, options.pullRequestNumber]);

    return res;
};
