{
    "en": [
        "access-default",
        "access-tracking",
        "admonition",
        "complaint",
        "erasure-default",
        "objection-default",
        "rectification-default"
    ],
    "cs": [
        "access-default",
        "access-tracking",
        "admonition",
        "complaint",
        "erasure-default",
        "objection-default",
        "rectification-default"
    ],
    "es": [
        "access-default",
        "access-tracking",
        "admonition",
        "complaint",
        "erasure-default",
        "objection-default",
        "rectification-default"
    ],
    "de": [
        "access-bundesjustizamt",
        "access-bundespolizei",
        "access-default",
        "access-drk-brandenburg-datenleck",
        "access-evangelische-kirche",
        "access-katholische-kirche",
        "access-lka-nds",
        "access-tracking",
        "admonition-evangelische-kirche",
        "admonition-katholische-kirche",
        "admonition",
        "complaint-evangelische-kirche",
        "complaint-katholische-kirche",
        "complaint",
        "erasure-default",
        "erasure-evangelische-kirche",
        "erasure-katholische-kirche",
        "objection-default",
        "objection-katholische-kirche",
        "rectification-default",
        "rectification-evangelische-kirche",
        "rectification-katholische-kirche"
    ],
    "hr": [
        "access-default",
        "access-tracking",
        "admonition",
        "complaint",
        "erasure-default",
        "objection-default",
        "rectification-default"
    ],
    "fr": [
        "access-default",
        "access-tracking",
        "admonition",
        "complaint",
        "erasure-default",
        "objection-default",
        "rectification-default"
    ],
    "it": [
        "access-default",
        "access-tracking",
        "admonition",
        "complaint",
        "erasure-default",
        "objection-default",
        "rectification-default"
    ],
    "el": [
        "access-default",
        "access-tracking",
        "admonition",
        "complaint",
        "erasure-default",
        "rectification-default"
    ],
    "nl": [
        "access-default",
        "access-tracking",
        "admonition",
        "complaint",
        "erasure-default",
        "objection-default",
        "rectification-default"
    ],
    "pt": [
        "access-default",
        "access-tracking",
        "admonition",
        "complaint",
        "erasure-default",
        "rectification-default"
    ],
    "ro": [
        "access-default"
    ],
    "sv": [
        "admonition",
        "complaint",
        "rectification-default"
    ]
}