import c from 'classnames';
import { type ComponentChildren } from 'preact';
import { useState } from 'preact/hooks';

export type DropdownProps = {
    className?: string;
    color?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';

    children: ComponentChildren;
    items: (
        | { type: 'link'; children: ComponentChildren; href: string }
        | { type: 'button'; children: ComponentChildren; onClick: (helpers: { closeDropdown: () => void }) => void }
        | { type: 'divider' }
    )[];
};

export const Dropdown = (props: DropdownProps) => {
    const [open, setOpen] = useState(false);

    return (
        <div className="dropdown">
            <button
                className={c('btn', 'dropdown-toggle', props.color ? `bg-${props.color}` : undefined, props.className)}
                type="button"
                aria-expanded={open}
                onClick={() => setOpen(!open)}>
                {props.children}
            </button>

            <ul className={c('dropdown-menu', { show: open })}>
                {props.items.map((item) => {
                    if (item.type === 'link')
                        return (
                            <li>
                                <a className="dropdown-item" href={item.href}>
                                    {item.children}
                                </a>
                            </li>
                        );

                    if (item.type === 'divider')
                        return (
                            <li>
                                <hr className="dropdown-divider" />
                            </li>
                        );

                    return (
                        <li>
                            <button
                                className="dropdown-item"
                                onClick={() => item.onClick({ closeDropdown: () => setOpen(false) })}>
                                {item.children}
                            </button>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
