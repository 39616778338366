import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const formatPhoneNumber = (value: unknown, options?: { promptForCountryIfNecessary?: boolean }) => {
    if (!value) return;

    const phoneNumberWithoutFallbackCountry = parsePhoneNumberFromString(value as string);
    if (phoneNumberWithoutFallbackCountry) return phoneNumberWithoutFallbackCountry.formatInternational();

    if (options?.promptForCountryIfNecessary === false) return;

    const country =
        prompt(
            'Enter a fallback country (as an ISO 3166-1 alpha-2 country code), e.g. "fr", "gb", or "de".'
        )?.toUpperCase() || '';
    return parsePhoneNumberFromString(value as string, country as 'DE')?.formatInternational();
};
